import motorbike from "../../assets/images/vintage-green-motorbike.avif";
import background1 from "../../assets/images/background1.webp";
import MarqueeComp from "./MarqueeComp";

const About = () => {
  return (
    <div className="w-full">
      <div
        className="h-screen flex justify-center items-center "
        style={{
          backgroundImage: `url(${background1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <section className=" dark:bg-gray-900">
          <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                Our vision is to simplify your life by taking care of your
                routine errands
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                Padi makes your life easy by providing reliable, fast, and
                affordable delivery and errand services.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-gray-100 py-10">
        <div className=" mx-auto px-6 md:px-12 lg:px-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-800">
              We are your Padi{" "}
              <i className="line-icon-Thumbs-UpSmiley text-background"></i>{" "}
            </h1>
            <p className="text-lg mt-4 text-gray-600">
              Delivering Excellence Across the Nation
            </p>
          </div>

          <div className="mt-10 md:flex md:justify-between gap-5 ">
            <div className="md:w-1/2 lg:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
              <h2 className="text-2xl font-semibold text-accent flex items-center gap-2">
                Our Mission{" "}
                <svg
                  className="w-6 h-6 text-background animate-bounce dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m10.051 8.102-3.778.322-1.994 1.994a.94.94 0 0 0 .533 1.6l2.698.316m8.39 1.617-.322 3.78-1.994 1.994a.94.94 0 0 1-1.595-.533l-.4-2.652m8.166-11.174a1.366 1.366 0 0 0-1.12-1.12c-1.616-.279-4.906-.623-6.38.853-1.671 1.672-5.211 8.015-6.31 10.023a.932.932 0 0 0 .162 1.111l.828.835.833.832a.932.932 0 0 0 1.111.163c2.008-1.102 8.35-4.642 10.021-6.312 1.475-1.478 1.133-4.77.855-6.385Zm-2.961 3.722a1.88 1.88 0 1 1-3.76 0 1.88 1.88 0 0 1 3.76 0Z"
                  />
                </svg>
              </h2>
              <p className="mt-4 text-darkHover text-center">
                At LifePadi, our mission is to provide reliable, fast, and
                affordable delivery services across the country. We strive to
                ensure every package is delivered on time, every time, with the
                utmost care.
              </p>
            </div>
            <div className="md:w-1/2 lg:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
              <h2 className="text-2xl font-semibold text-gray-800 flex items-center gap-2">
                Our Values{" "}
                <svg
                  className="w-6 h-6 text-background animate-bounce dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </h2>
              <p className="mt-4 text-darkHover text-center">
                We are committed to excellence in service, integrity in our
                operations, and innovation in our approach. These values guide
                everything we do, ensuring we meet and exceed customer
                expectations.
              </p>
            </div>
            <div className="md:w-1/2 lg:w-1/3 mb-8 md:mb-0 flex flex-col  items-center">
              <h2 className="text-2xl font-semibold text-accent flex gap-2 items-center">
                Why Choose Us?
                <svg
                  className="w-6 h-6 text-background animate-bounce dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.248 19C3.22 15.77 5.275 8.232 12.466 8.232V6.079a1.025 1.025 0 0 1 1.644-.862l5.479 4.307a1.108 1.108 0 0 1 0 1.723l-5.48 4.307a1.026 1.026 0 0 1-1.643-.861v-2.154C5.275 13.616 4.248 19 4.248 19Z"
                  />
                </svg>
              </h2>
              <p className="mt-4 text-darkHover text-center">
                With a vast network, cutting-edge technology, and a dedicated
                team, we offer the best delivery services in the industry. Our
                customers trust us for our reliability, efficiency, and
                commitment to excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Our team
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Our team at Lifepadi.com is dedicated to revolutionizing delivery 
            services with precision and care. We bring your needs to your doorstep 
            swiftly and efficiently, ensuring your satisfaction every step of the way
            </p>
          </div>
          <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <div className="text-center text-gray-500 dark:text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
              <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#"> Ugwuagba Benard</a>
              </h3>
              <p>CEO/Co-founder</p>
              <ul className="flex justify-center mt-4 space-x-4">
              <li>
                  <a
                    href="#"
                    className="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                
              </ul>
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/helene-engels.png"
                alt="Helene Avatar"
              />
              <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Amobi Onwurah</a>
              </h3>
              <p>Co-founder/CFO</p>
              <ul className="flex justify-center mt-4 space-x-4">
              <li>
                  <a
                    href="#"
                    className="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                
              </ul>
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                alt="Jese Avatar"
              />
              <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Ikechukwu Joshua </a>
              </h3>
              <p>Chief Operating Officer </p>
              <ul className="flex justify-center mt-4 space-x-4">
              <li>
                  <a
                    href="#"
                    className="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                
              </ul>
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
              <img
                className="mx-auto mb-4 w-36 h-36 rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png"
                alt="Joseph Avatar"
              />
              <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Mazi Vincent</a>
              </h3>
              <p>CTO</p>
              <ul className="flex justify-center mt-4 space-x-4">
               
                <li>
                  <a
                    href="#"
                    className="text-[#00acee] hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                
              </ul>
            </div>
           
          </div>
        </div>
      </section>
      <h2 className="mb-4 text-4xl text-center tracking-tight font-extrabold text-accent dark:text-white">
        Our Core Values
      </h2>
      <section className="bg-accent h-72 flex justify-center items-center mt-10">
        <MarqueeComp />
      </section>
    </div>
  );
};

export default About;
