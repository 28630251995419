const Gift = () => {
  return (
    <div className="flex items-center justify-center">
      <div>
        <h1 className="text-3xl text-green-500 font-bold">
          {" "}
          Your Gifts will be here Soon ... <span> <i className="line-icon-Smile text-yellow"></i> </span>{" "}
        </h1>
      </div>
    </div>
  );
};

export default Gift;
